exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-how-it-works-index-tsx": () => import("./../../../src/pages/how-it-works/index.tsx" /* webpackChunkName: "component---src-pages-how-it-works-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-agreement-index-tsx": () => import("./../../../src/pages/legal/agreement/index.tsx" /* webpackChunkName: "component---src-pages-legal-agreement-index-tsx" */),
  "component---src-pages-legal-cookies-policy-index-tsx": () => import("./../../../src/pages/legal/cookies-policy/index.tsx" /* webpackChunkName: "component---src-pages-legal-cookies-policy-index-tsx" */),
  "component---src-pages-legal-impressum-index-tsx": () => import("./../../../src/pages/legal/impressum/index.tsx" /* webpackChunkName: "component---src-pages-legal-impressum-index-tsx" */),
  "component---src-pages-legal-privacy-index-tsx": () => import("./../../../src/pages/legal/privacy/index.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-index-tsx" */),
  "component---src-pages-legal-terms-index-tsx": () => import("./../../../src/pages/legal/terms/index.tsx" /* webpackChunkName: "component---src-pages-legal-terms-index-tsx" */),
  "component---src-pages-moi-cheki-onlain-index-tsx": () => import("./../../../src/pages/moi-cheki-onlain/index.tsx" /* webpackChunkName: "component---src-pages-moi-cheki-onlain-index-tsx" */)
}

